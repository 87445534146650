import { Injectable } from "@angular/core";
import { Store, select } from "@ngrx/store";
import { token } from "./auth/state/auth/auth.selectors";
import { environment } from "src/environments/environment";
import { Observable, catchError, filter, map, of, shareReplay, switchMap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ListResponse, SingleResponse, Site, SiteCreateResponse, SiteDomain } from "./synaps-space.types";

@Injectable({
  providedIn: 'root',
})
export class SynapsMediaApiService {
  token = this.store.pipe(
    select(token),
    shareReplay(),
  );
  apiUrl = environment.apiUrl;

  constructor(private store: Store, private http: HttpClient) {

  }

  getBillingPortalUrl() {
    return this.tokenRequest(`${this.apiUrl}/users/me/customer-portal`, 'GET', null);
  }

  checkServiceNameAvailability(serviceName: string): Observable<boolean> {
    console.log('checking service name availability', serviceName);

    return this.getSite(serviceName).pipe(
      map(() => false),
      catchError(() => of(true))
    );
  }

  createSite(serviceName: string) {
    return this.tokenRequest<SiteCreateResponse>(`${this.apiUrl}/sites`, 'POST', {
      serviceName
    });
  }

  getSites() {
    return this.tokenRequest<ListResponse<Site>>(`${this.apiUrl}/sites`, 'GET', null);
  }

  getSite(siteId: string) {
    return this.tokenRequest<Site>(`${this.apiUrl}/sites/${siteId}`, 'GET', null);
  }

  getSiteDomains(siteId: number | string) {
    return this.tokenRequest<ListResponse<SiteDomain>>(`${this.apiUrl}/sites/${siteId}/domains`, 'GET', null);
  }

  getSitePaymentUrl(siteId: string): Observable<string> {
    return this.tokenRequest(`${this.apiUrl}/sites/${siteId}/payment`, 'GET', null).pipe(
      map((response: any) => response.result)
    );
  }

  addDomain(siteId: string, domain: string, main: boolean) {
    return this.tokenRequest<SingleResponse<SiteDomain>>(`${this.apiUrl}/sites/${siteId}/domains`, 'POST', { domain, main });
  }

  checkDomain(siteId: string, domain: string) {
    return this.tokenRequest(`${this.apiUrl}/sites/${siteId}/domains/check`, 'POST', { domain });
  }

  getSiteDomain(siteId: string|number, domainId: string|number) {
    return this.tokenRequest<SingleResponse<SiteDomain>>(`${this.apiUrl}/sites/${siteId}/domains/${domainId}`, 'GET', null);
  }

  updateDomain(siteId: string|number, domainId: string|number, main: boolean) {
    return this.tokenRequest(`${this.apiUrl}/sites/${siteId}/domains/${domainId}`, 'PATCH', { main });
  }

  deleteDomain(siteId: string|number, domainId: string|number) {
    return this.tokenRequest(`${this.apiUrl}/sites/${siteId}/domains/${domainId}`, 'DELETE', null);
  }

  private tokenRequest<T>(url: string, method: string, body: any): Observable<T> {
    return this.token.pipe(
      filter((token) => !!token),
      switchMap((token) => {
        return this.http.request<T>(method, url, {
          body,
          headers: {
            authorization: `Bearer ${token}`
          }
        });
      })
    );
  }
}
